import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {
    render() {


        return (
            <Layout>
                <Helmet
                    title="Abi's - Home Page"
                    meta={[
                        { name: 'description', content: 'Home Page' },
                        { name: 'keywords', content: '@abi, @abishekk, Abi, Abishek, Home, Page' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <Articles />
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Latest App!</h2>
                            </header>
                            <p>I am currently promoting a website setup. Check it out, its called Shutdown.</p>
                            <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

const Articles = () => {
    const articleElements = [
        {
            "header": "First",
            "backgroundImage": `url(${pic01})`,
            "text": "Let me write a paragraph here, a short description of what I am doing and\
            how that will be formatted in there. That's about it."
        },
        {
            "header": "Second",
            "backgroundImage": `url(${pic02})`,
            "text": "Ipsum dolor sit amet"
        },
        {
            "header": "Third",
            "backgroundImage": `url(${pic03})`,
            "text": "Ipsum dolor sit amet"
        },
        {
            "header": "Fourth",
            "backgroundImage": `url(${pic04})`,
            "text": "Ipsum dolor sit amet"
        },
    ]
    return (
        <section id="one" className="tiles">
            {articleElements.map((value, index) => {
                return <article key={index} style={{backgroundImage: value.backgroundImage}}>
                    <header className="major">
                        <h3>{value.header}</h3>
                        <p>{value.text}</p>
                    </header>
                    <Link to="/landing" className="link primary"></Link>
                </article>
            })}                        
        </section>
    )
}

export default HomeIndex